import { useColorMode } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth.store'
import { http } from '@/services/http.service'
import { useToast } from '@/components/ui/toast/use-toast'

export function useTheme() {
  const mode = useColorMode({
    attribute: 'class',
    initialValue: 'light',
    disableTransition: false,
  })

  const authStore = useAuthStore()
  const { toast } = useToast()

  const setTheme = (theme: 'light' | 'dark') => {
    mode.value = theme
    // Asegurarse que el DOM refleje el tema
    document.documentElement.classList.remove('light', 'dark')
    document.documentElement.classList.add(theme)
  }

  const toggleTheme = async () => {
    const newTheme = mode.value === 'light' ? 'dark' : 'light'

    if (authStore.user) {
      try {
        const response = await http.patch(`/users/update_manual/${authStore.user.id}`, {
          user: { theme: newTheme },
        })

        localStorage.setItem('user', JSON.stringify(response.data))
        setTheme(newTheme)

        toast({
          title: 'Tema actualizado',
          description: `Se ha cambiado al modo ${newTheme === 'light' ? 'claro' : 'oscuro'}.`,
        })
      } catch (error) {
        toast({
          title: 'Error al cambiar el tema',
          description: 'Por favor, inténtalo de nuevo más tarde.',
        })
      }
    } else {
      // Si no hay usuario, solo cambiamos el tema localmente
      setTheme(newTheme)
    }
  }

  const initializeTheme = () => {
    // Agregar clases de transición
    document.documentElement.classList.add('transition-colors')
    document.documentElement.classList.add('duration-200')
    document.body.classList.add('transition-colors')
    document.body.classList.add('duration-200')

    // Obtener el tema inicial
    const savedTheme = authStore.user?.theme
    const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    const initialTheme = savedTheme || systemTheme || 'light'

    // Establecer el tema inicial
    setTheme(initialTheme as 'light' | 'dark')
  }

  return {
    mode,
    setTheme,
    toggleTheme,
    initializeTheme,
  }
}
